/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
const NewsSingleDark = dynamic(() => import("@/components/NewsSingleDark"));

const NewsSliderNews = ({ title, type, newsSlug }) => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    if (type == "slider") {
      getNewsData();
    } else if (type == "relatedSlider") {
      getRelatedNewsData();
    } else if (type == "subCategory") {
      getSubCategoryNews();
    }
  }, [newsSlug]);

  const getNewsData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/news/latest/list/data"
      );
      setNews(await res.json());
    } catch (err) {
      console.log(err);
    }
  };
  const getRelatedNewsData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL +
          "/frontend-bn/related/news/list/data/" +
          newsSlug
      );
      setNews(await res.json());
    } catch (err) {
      console.log(err);
    }
  };
  const getSubCategoryNews = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL +
          "/frontend-bn/news/slider/list/subcategory/137"
      );
      setNews(await res.json());
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <section className="section mb-0 blak-bg_setion">
      <div className="container-fluid">
        <div className="category-section-layout">
          <div className="section-title">
            <h2 className="title">{title}</h2>
          </div>

          <div className="row">
            {news.map((item, index) => (
              <div
                className="col-md-3 col-sm-6"
                key={index}
                itemScope
                itemType="http://schema.org/NewsArticle"
              >
                <NewsSingleDark item={item} />
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <Link
                href="/news/subcategory/vzgj727o"
                className="btn btn-primary-light btn-sm"
                aria-label={"আরও নিউজ"}
                prefetch={false}
              >
                আরও নিউজ
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default NewsSliderNews;
